






































import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";

@Component
export default class UserProfile extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }

  public goToEdit() {
    this.$router.push("/main/profile/edit");
  }

  public goToPassword() {
    this.$router.push("/main/profile/password");
  }
}
